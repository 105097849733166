<template>
  <div>
    <form v-on:submit.prevent="submitImprovements">
      <div>
        <div v-for="(improvement, index) in improvementList" :key="index">
          <h4 class="mt-4">Mejora</h4>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="form-label col-form-label-sm">Descripción</label>

                <b-form-input
                  v-model="improvement.descripcion[0]"
                  autocomplete="off"
                  list="my-list-id"
                ></b-form-input>

                <datalist id="my-list-id">
                  <option
                    v-for="(choice, index) in improvementChoicesArray"
                    :key="index"
                    >{{ choice }}</option
                  >
                </datalist>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="form-label col-form-label-sm"
                >Coste estimado [€]</label
              >
              <input
                type="number"
                step="0.01"
                v-model="improvement.coste_estimado"
                class="form-control"
              />
            </div>
            <div class="col-sm-4">
              <label class="form-label col-form-label-sm"
                >Reducción demanda de calefacción [%]</label
              >
              <input
                type="number"
                step="0.01"
                max="999.99"
                v-model="improvement.reduccion_calefaccion"
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <label class="form-label col-form-label-sm"
                >Reducción demanda refrigeración [%]</label
              >
              <input
                type="number"
                step="0.01"
                max="999.99"
                v-model="improvement.reduccion_refrigeracion"
                class="form-control"
              />
            </div>
            <div class="col-sm-4">
              <label class="form-label col-form-label-sm"
                >Reducción consumo total [%]</label
              >
              <input
                type="number"
                step="0.01"
                max="999.99"
                v-model="improvement.reduccion_consumo_total"
                class="form-control"
              />
            </div>
            <div class="col-sm-4">
              <label class="form-label col-form-label-sm"
                >Reducción emisiones totales [%]</label
              >
              <input
                type="number"
                step="0.01"
                max="999.99"
                v-model="improvement.reduccion_emisiones_total"
                class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-1">
              <button
                class="btn btn-danger mt-2"
                @click.prevent="removeImprovement(improvement)"
              >
                —
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary mt-4" @click.prevent="addImprovement">
          +
        </button>
      </div>
      <small class="text-danger" v-if="this.submitError">
        Ha habido un error. Por favor, revise los datos.
      </small>
      <small class="text-success " v-if="this.success">
        Edificio guardado correctamente.
      </small>

      <br />
      <button type="submit" class="btn btn-primary mt-4">
        Guardar y finalizar
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  data: () => ({
    buildingId: null,
    submitError: false,
    success: false,
    searchDescription: "",
    valueDescription: [],
    improvementChoicesArray: [],

    improvementArray: [],
  }),
  async mounted() {
    this.buildingId = this.$route.params.id;
    await this.getImprovements();
    await this.getImprovementChoices();
  },

  computed: {
    improvementList() {
      return this.improvementArray.filter((item) => {
        return !item.deleted;
      });
    },
  },

  methods: {
    parseForm() {
      const formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) formData.append(key, this.form[key]);
      });

      return formData;
    },

    async getImprovements() {
      const { data } = await api.get(
        `improvements/?building=${this.buildingId}`
      );
      this.improvementArray = data;
    },

    async getImprovementChoices() {
      const { data } = await api.get(`choices/improvements/`);
      this.improvementChoicesArray = data.choices;
    },

    addImprovement() {
      this.improvementArray.push({
        descripcion: [],
        descripcion_custom: undefined,
        coste_estimado: undefined,
        reduccion_calefaccion: undefined,
        reduccion_refrigeracion: undefined,
        reduccion_consumo_total: undefined,
        reduccion_emisiones_total: undefined,
      });
    },

    removeImprovement(improvement) {
      this.$set(improvement, "deleted", true);
      this.$set(this.improvementArray, improvement);
    },

    async submitImprovements() {
      this.submitError = false;
      this.success = false;
      const postImprovementList = this.improvementArray.filter((item) => {
        return !item.id && !item.deleted;
      });
      const putImprovementList = this.improvementArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteImprovementList = this.improvementArray.filter((item) => {
        return item.id && item.deleted;
      });

      try {
        postImprovementList.forEach(async (item) => {
          item["building"] = this.buildingId;
          await api.post("improvements/", item);
        });

        putImprovementList.forEach(async (item) => {
          item["building"] = this.buildingId;
          await api.put(`improvements/${item.id}/`, item);
        });

        deleteImprovementList.forEach(async (item) => {
          await api.delete(`improvements/${item.id}/`);
        });

        this.improvementArray = this.improvementList;
        this.success = true;
      } catch {
        this.submitError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
